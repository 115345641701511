@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Nunito, sans-serif;
  font-weight: 300;
}

h1, h2, h3 {
  color: rgb(54, 54, 54);
}

.App {
  transition: 600ms ease-in-out;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  width: 100vw;
  
  &.library-open {
    margin-left: 30%;
  }
}

@import "./player";
@import "./song";
@import "./library";
@import "./nav";