html {
  --scrollbarBG: #CFD8DC;
  --thumbBG: #90A4AE;
}
*::-webkit-scrollbar {
  width: 5px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: transparent;
}

.library-title {
  font-weight: 900;
}
.library {
  background: #fff;
  position: fixed;
  top: 0;
  width: 30vw;
  max-width: 320px;
  height: 100%;
  left: 0;
  box-shadow: 2px 2px 50px rgb(227, 227, 227);
  overflow-y: scroll;
  transform: translateX(-100%);
  transition: all 0.6s ease-in-out;
  opacity: 0;

  &.open {
    transform: translateX(0);
    opacity: 1;
  }

  h1 {
    padding: 2rem;
  }
}
.library-songs {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
}

.library-song {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 2rem 0.5rem 2rem;
  transition: 300ms;
  cursor: pointer;

  img {
    width: 30%;
    max-width: 70px;
    border-radius: 50%;
    margin: 0.2rem;
    transition: 800ms cubic-bezier(.88,.07,.38,.83); 
  }

  &:hover {
    background-color: rgb(219, 219, 243);
    padding-left: 2.5rem;
    padding-right: 1.5rem;

  }
}

.title-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  display: none;
  padding: 7px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 1.3;
  margin: 10px;
  background: var(--thumbBG);
  border-radius: 50%;
  overflow: hidden;
  color: #fff;
  cursor: pointer;
}
.song-description {
  padding-left: 0.5rem;
  h3 {
    font-size: 1rem;
    transition: 500ms;
  }
  h4 {
    font-size: 0.7rem;
    transition: 500ms;
  }
}

.selected {
  background-color: rgb(153, 153, 197);
  h1, h2, h3, h4 {
    font-weight: 600;
    color: rgb(5, 5, 35);
  }

  img {
    transform: scale(1.18);
    transform-origin: center;
  }
}

@media screen and (max-width: 768px) {

  .library {
    width: 100%;
    max-width: none;

  }
  .close-button {
    display: block;
  }
  .library-song img {
    max-width: 70px
  }
}