.player {
  height: 20vh;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.time-control {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  input {
    width: 100%;
    // padding: 1rem 0;
    background: transparent;
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;

  }
  p {
    padding: 1rem;
  }
}

input[type="range"] {

  &:focus {
    outline: none;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    opacity: 0;
  }
  &::-moz-range-thumb {
    -moz-appearance: none;
    opacity: 0;
    height: 16px;
    width: 16px;
  }
}

.track {
  height: 1rem;
  width: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid lightgray;
}

.animate-track {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  pointer-events: none;
  transition: 300ms ease;
}

.play-control {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  svg {
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .player {
    min-height: 0;
    // position: fixed;
    // bottom: 0;
    width: 100%;
    padding: 0 0 20px 0;
    background: #fff;
    // box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.15);
  }  
 .time-control {
   width: 90%;
 }
 .play-control {
  width: 66%;
  padding: 0 1rem;
 }
}