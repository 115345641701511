.song-container {
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    border-radius: 50%;
    height: 52%;
  }

  h2 {
    padding: 1% 0 0.5% 0;
  }

  h3 {
    font-size: 1rem;
  }
  .square-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: none;
  }
  .square {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 10px;
    margin-top: 20px;
    transition: 600ms;
  }
}


@media screen and (max-width: 768px) {
  .song-container {
    img {
      height: 30%;
    }
    // img {
    //   width: 70%;
    //   max-width: 350px;
    // }
  
  }

}

@media screen and (min-width: 1200px) {
  .song-container {
    img {
      height: 82%;
    }
    // img {
    //   width: 70%;
    //   max-width: 350px;
    // }
  
  }

}
