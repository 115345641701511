nav {
  height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-height: 50px;

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    border: 2px solid rgba(65, 65, 65, 1);
    padding: 0.5rem;
    transition: 0.3s ease-in-out;

    .button-icon {
      margin-left: 5px;
    }

    &:hover {
      background: rgba(65, 65, 65, 1);
      color: white;
    }
  }

}

@media screen and (max-width: 768px) {
  nav {
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
  }
}